<template>
  <header
    class="flex items-center justify-center rounded-b-[24px] bg-white p-4 pt-2 shadow-md lg:h-[112px] lg:rounded-b-[40px] appheader"
  >
    <div class="relative customContainer flex items-center justify-between lg:px-10">
      <component
        :is="linkComponent"
        v-bind="linkProps"
        class="flex cursor-pointer"
      >
        <img
          src="@dnd/ui-layer/assets/icons/dndLogoText.svg"
          class="h-[30px] w-[140px] lg:h-full lg:w-full"
        />
      </component>
      <div class="flex items-center gap-1 lg:gap-2">
        <UiButton
          small
          :square="authStore.isLoggedIn"
          type="secondary"
          @click="appStore.modals.backCall = true"
        >
          <UiIcon name="phone" />
          {{ authStore.isLoggedIn ? '' : 'Помощь' }}
        </UiButton>
        <div
          v-if="authStore.isLoggedIn"
          class="excluded flex gap-1 lg:gap-2"
        >
          <UiButton
            small
            square
            :notification="isChatNotificationActive"
            :type="isChatNotificationActive ? 'primary' : 'secondary'"
            @click="showChatNotificationHandler"
          >
            <UiIcon name="bell" />
          </UiButton>
          <UiButton
            small
            @click="toLong"
          >
            К заявке
          </UiButton>
        </div>
      </div>
      <Transition name="fade">
        <div
          v-if="isShowChatNotification"
          ref="chatNotification"
          v-click-outside="{
            handler: clickOutsideHandler,
            exclude,
          }"
          class="absolute w-[240px] z-50 bg-dnd_white p-5 lg:p-8 border-2 border-dnd_light_grey h-[133px] lg:w-[284px] lg:h-[168px] top-[49px] rounded-2xl lg:rounded-3xl right-12 lg:right-[109px] lg:top-20"
        >
          <div
            class="absolute right-3 top-3 lg:right-4 lg:top-4 h-fit w-fit cursor-pointer rounded-full bg-ui_light_grey p-2.5 transition-all hover:bg-ui_mid_grey"
            @click="isShowChatNotification = false"
          >
            <UiIcon
              fill="black"
              name="close"
              size="xs"
            />
          </div>
          <NuxtLink
            to="/chat"
            @click="isShowChatNotification = false"
          >
            <div class="flex flex-col gap-y-2">
              <span class="text-xs lg:text-sm text-dnd_dark_grey">
                {{ dateOfLastUnreadMessage }}
              </span>
              <span class="text-sm lg:text-base">{{ templateNotificationChatMessage }}</span>
              <span class="text-xs lg:text-sm text-dnd_dark_grey">Прочитать</span>
            </div>
          </NuxtLink>
        </div>
      </Transition>
    </div>
  </header>
</template>

<script setup>
import dayjs from '~/helpers/dayjs';

const route = useRoute();

const appStore = useAppStore();
const authStore = useAuthStore();
const chatStore = useChatStore();

const chatNotification = ref(null);
const isShowChatNotification = ref(false);

const toLong = () => {
  if (route.path !== '/long') {
    navigateTo('/long');
  }
};

const linkComponent = computed(() => {
  return route.name === 'login' ? 'a' : resolveComponent('NuxtLink');
});

const linkProps = computed(() => {
  return route.name === 'login'
    ? { href: 'https://denginadom.ru/vzyat-v-dolg/', external: true }
    : { to: '/' };
});

const unreadMessageCount = computed(() => {
  return chatStore.unreadMessages.length;
});

const dateOfLastUnreadMessage = computed(() => {
  if (unreadMessageCount.value > 0)
    return dayjs(chatStore.unreadMessages.pop().created_at).format('DD.MM.YYYY');
});

const templateNotificationChatMessage = computed(() => {
  return unreadMessageCount.value > 1
    ? 'У вас есть непрочитанные сообщения в чате'
    : 'У вас новое сообщение в чате';
});

const isChatNotificationActive = computed(() => {
  return route.name !== 'chat' && unreadMessageCount.value > 0;
});

const showChatNotificationHandler = () => {
  if (unreadMessageCount.value > 0) {
    isShowChatNotification.value = true;
  }
};

const exclude = () => {
  return document.querySelector('.excluded');
};

const clickOutsideHandler = () => {
  isShowChatNotification.value = false;
};
</script>

<style scoped></style>
