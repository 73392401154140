<template>
  <div>
    <div
      v-for="pos in positions"
      :key="pos"
      :class="positionClassesMapping[pos]"
    >
      <TransitionGroup
        :name="transitionName"
        tag="div"
        class="flex max-w-[400px] select-none flex-col gap-2"
        :class="[
          ['top-right', 'bottom-right'].includes(pos) && 'items-end',
          ['top-left', 'bottom-left'].includes(pos) && 'items-start',
          ['top-center', 'bottom-center'].includes(pos) && 'items-center',
        ]"
      >
        <div
          v-for="notification in mapping[pos]"
          :key="notification.id"
          class="w-fit"
        >
          <div
            class="alert w-fit cursor-pointer rounded-lg shadow-lg"
            :class="[
              alertClassesMapping[notification.type],
              {
                'pointer-events-auto':
                  !notification.hasOwnProperty('clickable') || notification.clickable === true,
                'cursor-not-allowed': notification.closable === false,
              },
            ]"
            @click="mappedStore.deleteNotification(notification)"
          >
            <div class="flex w-full items-center gap-4">
              <div>
                <UiIsLoading v-if="notification.isLoading" />
                <UiIcon
                  v-else
                  :name="notification.type"
                  size="md"
                />
              </div>
              <div>
                <span>{{ notification.message }}</span>
              </div>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
// import YmiIcon from './Icon';
// import YmiIsLoading from './IsLoading';

const props = defineProps({
  transitionName: {
    type: String,
    default: 'fade',
  },
  defaultPosition: {
    type: String,
    default: 'top-center',
  },
  mappedStore: {
    type: Object,
    required: true,
  },
});

const positionClassesMapping = {
  'top-right': 'notices flex justify-end',
  'top-left': 'notices flex justify-start',
  'top-center': 'notices flex justify-center',
  'bottom-right': 'notices flex flex-row-reverse items-end justify-start',
  'bottom-left': 'notices flex flex-row-reverse items-end justify-end',
  'bottom-center': 'notices flex flex-row-reverse items-end justify-center',
};

const alertClassesMapping = {
  info: 'alert-info',
  warning: 'alert-warning',
  error: 'alert-error',
  success: 'alert-success',
};

const positions = computed(() => {
  return Object.keys(positionClassesMapping);
});

const mapping = computed(() => {
  const target = {};
  Object.keys(positionClassesMapping).forEach((k) => {
    target[k] = props.mappedStore.noties.filter((n) => {
      if (!n.position) return k === props.defaultPosition;
      else return n.position === k;
    });
  });

  return target;
});
</script>

<script>
export default {
  name: 'MainNotification',
};
</script>

<style lang="postcss" scoped>
.alert-error {
  @apply bg-dnd_white border-dnd_red rounded-2xl border-2;
}

.alert-success {
  @apply bg-dnd_white border-dnd_green rounded-2xl border-2;
}

.alert-warning {
  @apply bg-dnd_white border-dnd_yellow rounded-2xl border-2;
}

.alert-info {
  @apply bg-dnd_white border-dnd_blue rounded-2xl border-2;
}

.notices {
  @apply p-4;
  max-width: 100vw;
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  pointer-events: none;
  /* flex-direction: column; */
}
</style>
